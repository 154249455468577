import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { WARD_POINTS } from '../../App.config'

// Import Components
import {  Row, Col, Card, Image, Space } from 'antd'
import { DatabaseOutlined, DollarOutlined, EnvironmentOutlined, HomeOutlined, NumberOutlined, SyncOutlined, UserOutlined } from '@ant-design/icons'

class HoldingDetails extends React.PureComponent {
  // Get Clicked Object Data
  _getClickedObjectData = (wardPointsDataset, clicked) => {
    if (!wardPointsDataset?.dataContainer?._rows?.length || wardPointsDataset?.dataContainer?._rows?.length <= clicked?.index) {
      return {}
    }

    const fields = wardPointsDataset.fields
    const clickedRow = wardPointsDataset.dataContainer._rows[clicked?.index]
    const clickedObject = {}
    fields.forEach(f => {
      clickedObject[f.name] = clickedRow[f.fieldIdx]
    })

    return clickedObject
  }

  render() {
    const { wardPointsDataset, clicked } = this.props

    const data = clicked && clicked.object && clicked.object.properties ?
      clicked.object.properties
      :
      this._getClickedObjectData(wardPointsDataset, clicked)

    return (
      <div style={containerStyles}>
        <div style={{ width: '100%' }}>
          <Row
            justify='space-between'
            style={{
              width: '100%'
            }}
          >
            <Col xs={24}>
              {
                data?.Address ?
                  <div style={{ display: "flex" }}>
                    {/* <span style={{fontWeight:"bold"}}>Address: </span> */}
                    <EnvironmentOutlined />
                    <p
                      style={address}>
                      {data?.Address}
                    </p>
                  </div>
                  : ""

              }

              <div>
                <Card
                // style={{background: "#F5F5F5"}}
                >
                  <div style={{ display: "flex" }}>
                    <UserOutlined />
                    <p style={iconGap}>Owner: <span style={{ fontWeight: "bold" }}>{data?.owner_name}</span></p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <HomeOutlined />
                    <p style={iconGap}>Holding No: <span>{data?.houseno}</span></p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <NumberOutlined />
                    <p style={iconGap}>E-holding: <span>{data?.eholding}</span></p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <DatabaseOutlined />
                    <p style={iconGap}>Flat Number: <span>{data?.flat}</span></p>
                  </div>
                  <Space>
                    <SyncOutlined spin />
                    <div>Status:
                      {
                        data?.status === "DUE" ?
                          <span
                            style={{ ...iconTag, background: "#dc143c" }}
                          >
                            {data?.status}
                          </span>
                          :
                          <span
                            style={{ ...iconTag, background: "#7aa457" }}
                          >
                            {data?.status}
                          </span>
                      }
                    </div>
                  </Space>
                  <div style={{ display: "flex", marginTop: "14px" }}>
                    <DollarOutlined />
                    <p style={iconGap}>Current Due: <span>{data?.currdue}</span></p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <DollarOutlined />
                    <p style={iconGap}>Previous Due: <span>{data?.prevdue}</span></p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <DollarOutlined />
                    <p style={iconGap}>Q Tax: <span>{data?.qtax}</span></p>
                  </div>
                </Card>
              </div>
              <Image
                // src={ `${ AUTH_URL }${ data?.image_url ?? '' }` }
                src={"https://media-cdn.tripadvisor.com/media/photo-s/1d/00/4a/31/hotel-building.jpg"}
                alt='Holding Cover'
                width='100%'
                height=''
              />
            </Col>
          </Row>
        </div>

        {/* <div style={{ width: '100%', padding: '16px', borderRadius: '4px', border: '1px solid #f0f0f0' }}>
          <Row
            gutter={[ 16, 16 ]}
            justify='space-between'
            style={{
              width: '100%'
            }}
          >
            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Holdings Revenue'
                  value={ data.holdings_revenue }
                  suffix={
                    <span>{ 'Cr' }</span>
                  }
                  valueStyle={{
                    color: '#2ddbac',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='TL Revenue'
                  value={ data.tl_revenue }
                  suffix={
                    <span>{ 'Cr' }</span>
                  }
                  valueStyle={{
                    color: '#2ddbac',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Total Holdings'
                  value={ data.total_holdings }
                  valueStyle={{
                    color: '#2ddbac',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Total TL'
                  value={ data.total_tl }
                  valueStyle={{
                    color: '#2ddbac',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Unpaid Holdings'
                  value={ data.unpaid_holdings }
                  valueStyle={{
                    color: '#c70039',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Unpaid TL'
                  value={ data.unpaid_tl }
                  valueStyle={{
                    color: '#c70039',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>
          </Row>
        </div> */}



        {/* <div style={{ padding: '16px', borderRadius: '4px', border: '1px solid #f0f0f0' }}>
          <Table
            title={() => (
              <h5
                style={{
                  margin: 0,
                  width: '100%',
                  textAlign: 'center',
                  color: '#808080'
                }}
              >
                { locale === 'bn' ?
                  'মাস্টার ডাটা'
                  :
                  'Master Data'
                }
              </h5>
            )}
            dataSource={ dataSource }
            columns={ columns }
            pagination={ false }
            scroll={{
              y: 200
            }}
            size='small'
            onHeaderRow={() => ({ style: { fontSize: '11px' } })}
            onRow={() => ({ style: { fontSize: '11px' } })}
          />
        </div>

        <div style={{ padding: '16px', borderRadius: '4px', border: '1px solid #f0f0f0' }}>
          <Table
            title={() => (
              <h5
                style={{
                  margin: 0,
                  width: '100%',
                  textAlign: 'center',
                  color: '#808080'
                }}
              >
                { locale === 'bn' ?
                  'প্রপার্টি ডিটেলস'
                  :
                  'Property Details'
                }
              </h5>
            )}
            dataSource={ dataSource2 }
            columns={ columns2 }
            pagination={ false }
            scroll={{
              y: 200
            }}
            size='small'
            onHeaderRow={() => ({ style: { fontSize: '11px' } })}
            onRow={() => ({ style: { fontSize: '11px' } })}
          />
        </div>

        <div style={{ padding: '16px', borderRadius: '4px', border: '1px solid #f0f0f0' }}>
          <Table
            title={() => (
              <h5
                style={{
                  margin: 0,
                  width: '100%',
                  textAlign: 'center',
                  color: '#808080'
                }}
              >
                { locale === 'bn' ?
                  '১০ বছরের ডাটা'
                  :
                  '10 Year Data'
                }
              </h5>
            )}
            dataSource={ dataSource3 }
            columns={ columns3 }
            pagination={ false }
            scroll={{
              y: 200
            }}
            size='small'
            onHeaderRow={() => ({ style: { fontSize: '11px' } })}
            onRow={() => ({ style: { fontSize: '11px' } })}
          />
        </div>

        <div style={{ width: '100%' }}>
          <Divider
            plain={ true }
            style={{
              margin: 0,
              marginBottom: '8px',
              width: '100%',
              color: '#a0a0a0'
            }}
          >
            { 'Export Data' }
          </Divider>

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              gap: '8px'
            }}
          >
            <Button
              size='small'
              type='dashed'
            >
              { 'Excel' }
            </Button>

            <Button
              size='small'
              type='primary'
            >
              { 'PDF' }
            </Button>
          </div>
      </div> */}
      </div>
    )
  }
}

// JSS Styles
const containerStyles = {
  padding: '16px',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '16px',
  overflow: 'auto'
}

const iconTag = {
  marginLeft: '5px',
  marginTop: '-5px',
  // padding: '5px',
  paddingLeft: '10px',
  paddingRight: '10px',
  background: '#40A9FE',
  color: "white",
  borderRadius: '10px'
}

const address = {
  marginLeft: '5px',
  marginTop: '-5px',
  paddingLeft: '10px',
  paddingRight: '10px',
  fontSize: '16px',
  fontWeight: 'bold',
  // color: "black",
  borderRadius: '10px'
}


const iconGap = {
  marginTop: '-5px',
  paddingLeft: '5px',
  paddingRight: '10px',
}

// Prop Types
HoldingDetails.propTypes = {
  eHoldings: PropTypes.array,
  locale: PropTypes.string,
  clicked: PropTypes.object
}

HoldingDetails.defaultProps = {
  eHoldings: [],
  locale: 'en',
  clicked: null
}

const mapStateToProps = state => ({
  locale: state.locale.locale,
  wardPointsDataset: state?.keplerGl?.map?.visState?.datasets[WARD_POINTS.DATA_ID] ?? {}
})

export default connect(mapStateToProps)(HoldingDetails)