import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Row, Col, Modal } from "antd";
import MapComponent from "../Map/ReactMapGL";
import PropTypes from 'prop-types';
import axios from "axios";
import { ADMIN } from "../../App.config";

const UpdateEHoldingModalForm = ({ visible, onCancel, onSubmit, initialValues }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            await axios.put(`${ADMIN.UPDATE_EHOLDING_API}/${initialValues?.id}`, values, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': 'Bearer ' + localStorage.getItem('token')
                    'X-API-KEY': 'bkoi_38b527ad4d372c58270e7b04fca4e29c39befad01e52aaffef855dab0fbfe9de'
                }
            }).then((res) => {
                console.log(res)
                if (res.status === 200) {
                    message.success(res.data.message);
                }
                onSubmit();
            }).catch((error) => {
                const errorMessage = error.response?.data?.message || 'Failed to update data. Please try again.';
                if (typeof errorMessage === 'object') {
                    Object.values(errorMessage).forEach(msgArray => {
                        msgArray.forEach(msg => message.error(msg)); //NOSONAR
                    });
                } else {
                    message.error(errorMessage);
                }
            }).finally(() => {
                setLoading(false);
            });
        } catch (error) {
            console.error(error);
            message.error('Failed to save data. Please try again.');
        }
    };

    const onFinishFailed = (errorInfo) => {
        message.error("Please correct the errors in the form.");
        console.log("Failed:", errorInfo);
    };


    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [visible, initialValues, form]);


    return (



        <Modal
            title={`Update E-Holding`}
            visible={visible}
            onCancel={onCancel}
            width={1515}
            style={{ top: 10 }}
            footer={[
                <Button key="cancel" onClick={onCancel} style={{
                    background: '#FFFFFF',
                    borderColor: '#D9D9D9',
                    color: '#333',
                    fontWeight: 'bold',
                    borderRadius: '2px',
                }}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={() => form.submit()}
                    style={{
                        background: '#327AA6',
                        borderColor: '#327AA6',
                        color: 'white',
                        fontWeight: 'bold',
                        borderRadius: '2px',
                    }}>
                    {'Update'}
                </Button>,
            ]}

        >
            <Row gutter={[16, 16]}>
                <Col xs={24} lg={16}>
                    {/* Map Section */}
                    <div
                        style={{
                            height: "100%",
                            backgroundColor: "#f0f2f5",
                            border: "1px solid #d9d9d9",
                            borderRadius: "4px",
                        }}
                    >
                        {/* Map will be added here */}
                        <MapComponent onMarkerDrag={(value) => {
                            form.setFieldsValue({ latitude: value.latitude, longitude: value.longitude });
                        }} />
                    </div>
                </Col>
                <Col xs={24} lg={8}>
                    <Form
                        form={form}
                        name="updateForm"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"

                    >
                        <Form.Item
                            label="Owner Name"
                            name="owner_name"
                            style={{ marginBottom: 8 }}
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label="Holding No."
                            name="houseno"
                            style={{ marginBottom: 8 }}
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label="E-holding"
                            name="eholding"
                            style={{ marginBottom: 8 }}
                        >
                            <Input disabled />
                        </Form.Item>


                        <Form.Item
                            label="Flat Number"
                            name="flat"
                            style={{ marginBottom: 8 }}
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label="Current Due"
                            name="currdue"
                            style={{ marginBottom: 8 }}
                        >
                            <Input disabled />
                        </Form.Item>
                        <Form.Item
                            label="Previous Due"
                            name="prevdue"
                            style={{ marginBottom: 8 }}
                        >
                            <Input disabled />
                        </Form.Item>
                        <Form.Item
                            label="Q Tax"
                            name="qtax"
                            style={{ marginBottom: 8 }}
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label="Latitude"
                            name="latitude"
                            style={{ marginBottom: 8 }}
                            rules={[
                                { required: true, message: "Please input the latitude!" },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Longitude"
                            name="longitude"
                            style={{ marginBottom: 8 }}
                            rules={[
                                { required: true, message: "Please input the longitude!" },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Address"
                            name="address"
                            style={{ marginBottom: 8 }}
                            rules={[
                                { required: true, message: "Please input the address!" },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="U-Code"
                            name="uCode"
                            style={{ marginBottom: 8 }}
                            rules={[
                                { required: true, message: "Please input the U-Code!" },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
};
UpdateEHoldingModalForm.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        id: PropTypes.string,
        owner_name: PropTypes.string,
        houseno: PropTypes.string,
        eholding: PropTypes.string,
        flat: PropTypes.string,
        currdue: PropTypes.string,
        prevdue: PropTypes.string,
        qtax: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        address: PropTypes.string,
        uCode: PropTypes.string,
    }).isRequired,
};

export default UpdateEHoldingModalForm;
