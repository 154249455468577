import React, { useEffect, useMemo, useState } from "react";
import {
  Layout,
  Table,
  Button,
  Input,
  Avatar,
  Menu,
  Dropdown,
  Select,
} from "antd";
import {
  MoreOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import UpdateWithApprovalModal from "../components/Admin/UpdateWithApprovalModal";
import UpdateEHoldingModalForm from "../components/Admin/UpdateEHoldingModalForm";
import { ADMIN } from "../App.config";
import axios from "axios";

const { Header, Content, Sider } = Layout;
const { Search } = Input;

const Admin = () => {
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [collapsed, setCollapsed] = React.useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState('add'); // 'add' or 'edit'
  const [currentRecord, setCurrentRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("owner_name");

  const getHoldings = async (current_page, per_page_eholding, col, val) => {
    setLoading(true);
    try {

      const response = await axios.get(ADMIN.GET_HOLDINGS_API, {
        params: {
          per_page_eholding,
          page: current_page,
          [col]: val
        },
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'Bearer ' + localStorage.getItem('token')
          'X-API-KEY': 'bkoi_38b527ad4d372c58270e7b04fca4e29c39befad01e52aaffef855dab0fbfe9de'
        }
      });

      console.log(response);
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      setData(response?.data);

    } catch (error) {
      setData({});
      console.error('Failed to fetch holdings:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getHoldings(1, pageSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataSource = useMemo(() => {
    let tableData = [];
    data.data?.forEach(element => {
      tableData.push({
        ...element,
        key: element.id,
        ownerName: element.owner_name,
        holdingNo: element.houseno,
        eHolding: element.eholding,
        flatNumber: element.flat,
        currentDue: element.currdue,
        previousDue: element.prevdue,
        qTax: element.qtax,
        status: element.status ? "Active" : "Inactive",
      });
    });



    return tableData;
  }, [data]);

  const handleEdit = (record) => {
    setModalMode('edit');
    setCurrentRecord(record);
    setModalVisible(true);
  };

  const handleApproved = (record) => {
    setModalMode(null)
    setCurrentRecord(record);
    setModalVisible(true);
  }
  const handleModalSubmit = () => {
    setModalVisible(false)
    getHoldings(1, pageSize);
    // Refresh the data table or fetch updated records
  };

  const columns = [
    {
      title: "Owner Name",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Holding No.",
      dataIndex: "holdingNo",
      key: "holdingNo",
    },
    {
      title: "E-holding",
      dataIndex: "eHolding",
      key: "eHolding",
    },
    {
      title: "Flat Number",
      dataIndex: "flatNumber",
      key: "flatNumber",
    },
    {
      title: "Current Due",
      dataIndex: "currentDue",
      key: "currentDue",
    },
    {
      title: "Previous Due",
      dataIndex: "previousDue",
      key: "previousDue",
    },
    {
      title: "Q Tax",
      dataIndex: "qTax",
      key: "qTax",
    },
    {
      title: "Holding Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span
          style={{
            color: (() => {
              if (status === "Active") return "green";
              if (status === "Inactive") return "orange";
              return "red";
            })(),
          }}
        >
          {status}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, row) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1" onClick={() => handleEdit(row)}>Edit</Menu.Item>
              <Menu.Item key="2" onClick={() => handleApproved(row)}>Update with Approval</Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button icon={<MoreOutlined />} type="text" />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <Layout style={{ minHeight: "100vh", width: "100%", background: "#fff" }}>
        <Header
          style={{
            background: "#fff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 20px",
            boxShadow: "0 2px 8px #f0f1f2",
          }}
        >
          <h2 style={{ margin: 0 }}>Urban Engine</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 8 }}>Marcus</span>
            <Avatar src="https://i.pravatar.cc/300" />
            <DownOutlined style={{ marginLeft: 8 }} />
          </div>
        </Header>
        <Layout>
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            style={{
              background: "#fff",
              // boxShadow: "2px 0 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* <div
            style={{
              height: "64px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#000",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {collapsed ? "UE" : "Urban Engine"}
          </div>
          <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
            <Menu.Item key="1" icon={<MenuUnfoldOutlined />}>
              Dashboard
            </Menu.Item>
            <Menu.Item key="2" icon={<MenuFoldOutlined />}>
              Holdings
            </Menu.Item>
            <Menu.Item key="3" icon={<MenuUnfoldOutlined />}>
              Reports
            </Menu.Item>
            <Menu.Item key="4" icon={<MenuFoldOutlined />}>
              Settings
            </Menu.Item>
          </Menu> */}
          </Sider>

          <Content style={{ margin: "20px" }}>
            <div>
              <h3>Holding List</h3>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: 'flex', gap: 8 }}>
                  <Select
                    defaultValue={searchedColumn}
                    style={{ width: 150, marginBottom: 20 }}
                    onChange={(value) => {
                      setSearchedColumn(value);
                    }}
                    options={[
                      { value: 'owner_name', label: 'Owner Name' },
                      { value: 'house_no', label: 'Holding No' },
                      { value: 'eholding', label: 'E-holding' },
                    ].map(({ value, label }) => ({ value, label }))}
                  />
                  <Search
                    placeholder="Search"
                    onSearch={(val) => {
                      console.log(val);
                      getHoldings(1, pageSize, searchedColumn, val);
                    }}
                    enterButton
                    allowClear
                  />
                </div>
                {/* <Button type="primary" onClick={handleAdd} style={{
                  background: '#327AA6',
                  borderColor: '#327AA6',
                  color: 'white',
                  fontWeight: '600',
                  borderRadius: '5px',
                  minWidth: '170px',
                }}>Add Holding</Button> */}
              </div>
              <Table
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                pagination={{
                  defaultCurrent: data?.pagination?.current_page,
                  total: data?.pagination?.total_pages,
                  pageSize: data?.pagination?.per_page,
                  responsive: true,
                  onChange: (page) => {
                    console.log(page);
                    getHoldings(page, pageSize);
                  },
                  onShowSizeChange: (current, size) => {
                    setPageSize(size);
                    getHoldings(current, size);
                  },
                  showSizeChanger: true,
                  // showLessItems: true,
                }}
                bordered
                scroll={{ x: 1200 }}
                locale={{
                  emptyText: (
                    <div style={{ minHeight: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      No results found
                    </div>
                  ),
                }}
              />
            </div>
          </Content>
        </Layout>
      </Layout>

      {
        modalMode === 'edit' &&
        <UpdateEHoldingModalForm
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          onSubmit={handleModalSubmit}
          initialValues={currentRecord}
        />
      }

      {!modalMode && <UpdateWithApprovalModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onSubmit={handleModalSubmit}
        initialValues={currentRecord}
      />}
    </>
  );
};

export default Admin;