import * as React from 'react';
import { useState, useCallback } from 'react';
import Map, { Marker, NavigationControl } from 'react-map-gl';
import Pin from './Pin';


export default function ReactMapGL({ onMarkerDrag }) {
  const [viewport, setViewport] = useState({
    latitude: 23.821600277500405,
    longitude: 90.3938010872331,
    zoom: 8,
    width: '100%',
    height: '100%'
  });
  const [marker, setMarker] = useState({
    latitude: 23.821600277500405,
    longitude: 90.3938010872331
  });

  const _onMarkerDrag = useCallback((event) => {
    setMarker({
      longitude: event.lngLat[0],
      latitude: event.lngLat[1]
    });
    onMarkerDrag({
      longitude: event.lngLat[0],
      latitude: event.lngLat[1]
    });
  }, [onMarkerDrag]);



  return (
    <Map
      {...viewport}
      onViewportChange={nextViewport => setViewport(nextViewport)}
      mapStyle="https://geoserver.bmapsbd.com/styles/barikoi-custom/style.json?key=bkoi_38b527ad4d372c58270e7b04fca4e29c39befad01e52aaffef855dab0fbfe9de"
    >
      <Marker
        longitude={marker.longitude}
        latitude={marker.latitude}
        anchor="bottom"
        draggable
        // onDragStart={onMarkerDragStart}
        onDrag={_onMarkerDrag}
      // onDragEnd={onMarkerDragEnd}
      >
        <Pin size={20} />
        {/* <img width={ 50 } height={ 50 } alt='marker' src='/default.png' /> */}
        {/* <div style={{ position: 'absolute', top: '-30px', left: '-25px' }}> 
      </div> */}
      </Marker>

      <NavigationControl />
    </Map>

  );
}

